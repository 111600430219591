<template>
  <v-container fluid>
    <v-card elevation="4" class="mt-0">
      <v-tabs v-model="tab">
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <products-tab v-if="item.content == 'products'"></products-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    ProductsTab: () => import("./product-dialogs/ProductsTab.vue"),
  },

  data() {
    return {
      tab: null,
      items: [
        {
          tab: this.$t("products"),
          content: "products",
        },
      ],
    };
  },
};
</script>
